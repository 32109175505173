import React from 'react';
import ComplaintInfo from "./complaint_info";
import ComplaintForm from "./complaint_form";
import ComplaintAccepted from "./complaint_accepted";
import update from 'immutability-helper';
import {
    GoogleReCaptchaProvider,
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {Redirect} from "react-router-dom";

class ComplaintContainer extends React.Component {
    supportedApps = ['nova', 'epic', 'at', 'blue', 'vega', 'leto']

    constructor(props) {
        super(props);
        if (props.match.params
            && props.match.params.appId) {
            if (this.supportedApps.includes(props.match.params.appId)) {
                this.appId = props.match.params.appId
            }
        } else {
            this.appId = "no_app" // todo use sent_from_atplayer_com
        }


        this.state = {
            termsAccepted: false,
            complaintAccepted: false,
            token_info: '',
            token_form: '',
        }
    }

    onSubmitTerms = (val, token) => {
        this.setState(update(this.state, {
            token_info: {$set: token},
            termsAccepted: {$set: val}
        }))
    }

    onSubmitComplaint = (val, token) => {
        this.setState(update(this.state, {
            token_form: {$set: token},
            complaintAccepted: {$set: val}
        }))
    }

    renderComponent() {
        if (!this.appId) {
            console.log('REDIRECT TO /')
            return <Redirect to='/'/>
        }
        if (this.state.termsAccepted && !this.state.complaintAccepted) {
            let YourReCaptchaComponent = withGoogleReCaptcha(ComplaintForm);
            return < YourReCaptchaComponent onSubmitCallback={this.onSubmitComplaint} appId={this.appId}/>
        } else if (this.state.termsAccepted && this.state.complaintAccepted) {
            console.log(JSON.stringify(this.state))
            return < ComplaintAccepted/>
        } else {
            let YourReCaptchaComponent = withGoogleReCaptcha(ComplaintInfo);
            return < YourReCaptchaComponent onSubmitCallback={this.onSubmitTerms}/>
        }
    }

    render() {
        return (
            <GoogleReCaptchaProvider reCaptchaKey="6LcBY-gUAAAAAGq2-9zeXkND1pkTwUCWRFwD3pJ1">
                <div>
                    {(this.renderComponent())}
                </div>
            </GoogleReCaptchaProvider>
        );
    }
}

export default ComplaintContainer